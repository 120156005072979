import React, { useState, useEffect  } from 'react';
import './BoatDetails.css';
import { Image, Box, Flex
} from '@chakra-ui/react'


const BoatDetails = ({ buque, ocultar }) => {
  
  const formatDate = (fecha) => {
    const [year, month, day] = fecha.split('-');
    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
  };

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            ocultar({}, false);
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
        // Limpia el evento de escucha cuando el componente se desmonta
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [ocultar]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)"  // Fondo gris translúcido
      display="flex" justifyContent="center" alignItems="center"
      zIndex="2"
    >
        <Box position="fixed" top="50%" left="50%" w="100%" maxW="600px" p="25px" zIndex="3" background="rgb(241, 241, 241)"
            transform="translate(-50%, -50%)" boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)" boxSizing='border-box' borderRadius="8px"
        >
          <Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom="20px">
              <Image
                borderRadius='full'
                boxSize='90px'
                src={`/images/${buque.id}.jpg`}
                alt='barcoimg'
              />
              <Box flexGrow="1" fontSize="40px" marginLeft="20px">{buque.descrp}</Box>
              <Box background="rgba(0, 0, 0, 0.24)" color="white" border="none" borderRadius="50%" w="30px" h="30px" display="flex" alignItems="center" justifyContent="center"
                   cursor="pointer" fontSize="18px" zIndex="10"
                   onClick={() => ocultar({}, false)}>X</Box>
            </Box>
            <Box position="relative" w="100%">
              <Box position="absolute" display="flex" left="111" fontSize="22px" top="-9">
                <Box fontWeight="bold" marginRight="10px">Capacidad:</Box>
                <Box>{buque.cap_barco} TN</Box>
              </Box>
            </Box>

            <hr style={{ border: 'none', height: '2px', backgroundColor: '#000000', margin: '5px 0' }} />
            
          { buque.estado === 0 && buque.dique === 0 &&
            <Box>
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">Capitan:</Box>
                <Box>{buque.capitan}</Box>
              </Box>
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">Jefe de máquinas:</Box>
                <Box>{buque.jefe_mq}</Box>
              </Box>
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">N° Marea Anual:</Box>
                <Box>{buque.marea_anual}</Box>
              </Box>
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">N° Marea Histórica:</Box>
                <Box>{buque.marea_hist}</Box>
              </Box>
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">{buque.estado === 1 ?  "Fecha de Ult. Salida:" : buque.marea_preparacion === 1 ? "Fecha de Proxima Salida:" : "Fecha de Salida:"}</Box>
                <Box>{formatDate(buque.fechSal)}</Box>
              </Box>
              <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
              
              <Box display="flex" fontSize="20px">
                <Box fontWeight="bold" marginRight="10px">Estado:</Box>
                <Box>{buque.estado === 1 ? 'Puerto' : buque.ultimoParte === 'S' ? 'Volviendo' : buque.marea_preparacion === 1 ? "Puerto" : 'Navegando'}</Box>
              </Box>
            </Box>
          }
          
          { buque.estado === 0 && buque.dias_producidos === 0 &&
            <Box>
                <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
                  <Box display="flex" fontSize="30px">
                    <Box fontWeight="bold" marginRight="10px">EN NAVEGACIÓN A ZONA DE PESCA</Box>
                  </Box>
            </Box>
          }
          
            { buque.estado === 0 && buque.viajando > 0 && buque.dias_producidos > 0 &&
              <Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Días Navegados:</Box>
                  <Box className='boatDetails-data'>{buque.position}</Box>
                </Box>
                
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Días Restantes:</Box>
                  <Box> {buque.estado === 1 ? 0 : buque.viajando <= 4 ? (buque.viajando) : Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))+2}</Box>
                </Box>

                <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />

                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Fecha Primer Parte de Producción:</Box>
                  <Box>{formatDate(buque.fecha_primerParte)}</Box>
                </Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Días Producidos:</Box>
                  <Box>{buque.dias_producidos}</Box>
                </Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Fecha último Parte de Producción:</Box>
                  <Box>{formatDate(buque.fecha_ultParte)}</Box>
                </Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Toneladas capt. en últ. Parte:</Box>
                  <Box>{(buque.tons_ultParte)} TN</Box>
                </Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Promedio Diario:</Box>
                  <Box>{(buque.ton_dia/buque.dias_producidos).toFixed(2)} TN</Box>
                </Box>
                <Box display="flex" fontSize="20px">
                  <Box fontWeight="bold" marginRight="10px">Toneladas al Dia:</Box>
                  <Box>{buque.ton_dia} TN</Box>
                </Box>

                <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />

                <Box display="flex" fontSize="25px">
                  <Box fontWeight="bold" marginRight="10px">Toneladas Restantes:</Box>
                  <Box>{(buque.cap_barco - buque.ton_dia).toFixed(2)} TN</Box>
                </Box>
                
                <Box display="flex" fontSize="25px">
                  <Box fontWeight="bold" marginRight="10px">{buque.estado === 1 ? "Fecha Entrada Real:" : "Fecha Estimada de Entrada:"}</Box>
                  <Box> {buque.estado === 1 
                                                                          ? formatDate(buque.fechEnt) 
                                                                          : buque.dias_producidos === 0 ? formatDate(buque.fechEnt) : 
                                                                              formatDate(new Date(new Date().setDate(new Date().getDate() + 
                                                                              (buque.viajando <= 4 
                                                                                ? buque.viajando 
                                                                                : Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))+2
                                                                              ))).toISOString().split('T')[0])}</Box>
                </Box>
            </Box> 
            }  
            {buque.dique === 1 &&
              <Box>
                  <Box display="flex" fontSize="35px">
                    <Box fontWeight="bold" marginRight="10px">Buque en Dique</Box>
                  </Box>
                  <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Fecha Entrada al Dique:</Box>
                    <Box>{formatDate(buque.dique_fechaE)}</Box>
                  </Box>
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Fecha Estimada de Salida del Dique:</Box>
                    <Box>{formatDate(buque.dique_fechaS)}</Box>
                  </Box>
                  <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Trabajo que se esta realizando:</Box>
                  </Box>
                  <Box fontSize="20px">{buque.dique_obs}</Box>
              </Box>
            }
            {buque.dique === 2 &&
              <Box>
                  <Box display="flex" fontSize="35px">
                    <Box fontWeight="bold" marginRight="10px">Buque con Dique Programado</Box>
                  </Box>
                  <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Fecha Proxima Entrada al Dique:</Box>
                    <Box>{formatDate(buque.dique_fechaE)}</Box>
                  </Box>
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Fecha Estimada de Salida del Dique:</Box>
                    <Box>{formatDate(buque.dique_fechaS)}</Box>
                  </Box>
                  <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
                  <Box display="flex" fontSize="20px">
                    <Box fontWeight="bold" marginRight="10px">Trabajo que se realizará:</Box>
                  </Box>
                  <Box fontSize="20px">{buque.dique_obs}</Box>
              </Box>
            }
            {buque.estado === 1 && buque.dique === 0 &&
              <Box>
                  <Box display="flex" fontSize="25px">
                    <Box fontWeight="bold" marginRight="10px">Buque en Puerto</Box>
                  </Box>
                  <Box display="flex" fontSize="25px">
                    <Box fontWeight="bold" marginRight="10px">Sin información proxima Marea</Box>
                  </Box>
              </Box>
            }
          </Box>
        </Box>
    </Box>
  );
};

export default BoatDetails;
